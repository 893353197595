// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <a
//         className="App-link"
//         href="https://bluhet-backend1.net/"
//         target="_blank"
//         rel="noopener noreferrer"
//         ><img src="/images/Image 1_SMART.png" 
//         className="App-logo" 
//         alt="logo" />
//         </a>
//         <span>
//           GreenScent Smart Citizen education for a green future
//         </span>
//       </header>
//     </div>
//   );
// }

// export default App;


import React from 'react';
import './App.css';
import Home from "./components/Home";
// import NotFound from "./components/NotFound";
// import Welcome from "./components/Welcome";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  // styled, 
  createTheme,
  ThemeProvider
} from '@mui/material/styles';

// import { Suspense } from 'react';
// import { useTranslation } from 'react-i18next';




const theme = createTheme({
  palette: {
    primary: {
      main: "#004c43"
    },
    secondary: {
      main: "#ffb639 "
    }
  },
  components: {
    
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#ffb639",
            backgroundColor: "#004c43",
          },
          // selected: {
          //   "&&": {
          //     color: "#ffb639 !important",
          //     backgroundColor: "#004c43!important",
          //   }
          // },
          // "&:hover": {
          // color: '#000000',
          // backgroundColor: '#cecece'
          // },
          padding: "5px!important",
          color: "#004c43"
        },
      }
    },
    // MuiBottomNavigation: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "red",
    //     },
    //   },
    // },

    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: "primary",
          "&.Mui-selected": {
            color: "#ffb639 !important",
            backgroundColor: "#004c43!important",
          },
          // selected: {
          //   "&&": {
          //     color: "#ffb639 !important",
          //     backgroundColor: "#004c43!important",
          //   }
          // },
          // "&:hover": {
          // color: '#000000',
          // backgroundColor: '#cecece'
          // },
        },
      },
    },
    //  $Muiselected: {
    //     styleOverrides: {
    //       label: {
    //         fontSize: "2px!important",
    //       },
    //     },
    //   },
    //   .css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type) {
    //     margin-left: -1px;
    //     border-left: 1px solid #cf0e0e;
    //     border-top-left-radius: 0;
    //     border-bottom-left-radius: 0;
    // }

    MuiDayCalendar: {
      styleOverrides: {
        root: {
          backgroundColor: "#004c4380",
        },       
        // weekDayLabel: {
        //   color: "#CCC",
        // },
      },
    },

    MuiDateCalendar: {
      styleOverrides: {
        root: {
          color: "#fff",
          backgroundColor: "#008080",
        }
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },


    
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#fff",
          }
        },
      }
    },    


    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: "5px!important",
        }
      }
    },
    
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none"
        }
      }
    },


   MuiInputLabel :{
      styleOverrides: {
        root: {
          color: "#FFF!important",
          marginTop: "-5px!important",
         
        }
      }
    },
    MuiInput :{
      styleOverrides: {
        input: {
          marginTop: "-7px!important",

        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "#FFF!important"
            
        }
      }
    },
    MuiFormControl : {
      styleOverrides: {
        root: {
          height: "42px!important",
          border: '1px solid #ced4da',
         
        }
      }
    }

  }
});



function App() {
  // const { t, i18n } = useTranslation();

  return (

    // <Suspense fallback={(<div>Loading</div>)}>
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter >
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
    // </Suspense>

  );
}

export default App;

