import React, { Component } from 'react';

import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';

/*import {
  getLanguage
} from "../../utils/utility";*/

import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


// const LangLocale = navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage;


let initLan = (window.localStorage.getItem('i18nextLng'))


class DialogLanguage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "titolo dialog",
      info: "messaggio dialog",
      language: "",
      languagePreConfirm: "",
    };
  }

  componentDidMount() {

    // console.log(this.props.i18n.language, initLan, LangLocale);
    this.setState({ languagePreConfirm: initLan, language: initLan },
      // ()=> console.log("languagePreConfirm T " +this.state.languagePreConfirm + "language " +this.state.language)
    );

    this.props.i18n.changeLanguage(this.state.languagePreConfirm);
    // window.localStorage.setItem("language", this.state.languagePreConfirm);
    // console.log("STAMPO CURRENT : "+this.props.i18n.language); // STAMPO CURRENT

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.t !== prevProps.t) {
      // console.log("STAMPO NUOVA : "+this.props.i18n.language); // STAMPO NUOVA
    }

  }

  onclose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    // this.props.callback(this.state.language, this.state.languagePreConfirm)
    this.props.close();
    // this.setState({
    // open: !this.state.open
    //  })
  }



  changeLanguage() {
    this.setState({ language: this.state.languagePreConfirm },
      // ()=> console.log(this.state.language)
    );
    window.localStorage.setItem("language", this.state.languagePreConfirm);

    this.props.i18n.changeLanguage(this.state.languagePreConfirm);

    this.onclose();
  };


  render() {

    const { t } = this.props;

    return (

      <Suspense fallback={(<div>Loading</div>)}>
        <Dialog
          open={this.props.open}
          onClose={this.onclose}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth={'xs'}
        >
          <DialogContent>

            <Box style={{
              display: "flex",
              justifyContent: "center",
            }}>
              <RadioGroup
                aria-label="Languages"
                name="country"
                value={this.state.languagePreConfirm}
                onChange={(e) => this.setState({ languagePreConfirm: e.target.value },
                  // () => console.log(this.state.languagePreConfirm)
                )}
              >
                <span>
                  <FormControlLabel
                    value="ca"
                    control={<Radio />}
                    // label={<Typography
                    //   variant="h5">
                    //   <img alt="flag Catalonia"
                    //     style={{ width: 32, paddingRight: 10 }}
                    //     src="/images/lang/Catalonia.png" />
                    //   <span >Catalano</span>
                    // </Typography>} />
                    label={<Typography
                      variant="h5">
                      <span >CA - Català</span>
                    </Typography>} />
                </span>

                <span>
                  <FormControlLabel
                    value="es"
                    control={<Radio />}
                    // label={<Typography
                    //   variant="h5">
                    //   <img alt="flag Espana"
                    //     style={{ width: 32, paddingRight: 10 }}
                    //     src="/images/lang/Espana.png" />
                    //   <span >Español</span>
                    // </Typography>} />
                    label={<Typography
                      variant="h5">
                      <span >ES - Español</span>
                    </Typography>} />
                </span>

                <span>
                  <FormControlLabel
                    value="en"
                    control={<Radio />}
                    // label={<Typography
                    //   variant="h5">
                    //   <img alt="flag England"
                    //     style={{ width: 32, paddingRight: 10 }}
                    //     src="/images/lang/England.png" />
                    //   <span >English</span>
                    // </Typography>} />
                    label={<Typography
                      variant="h5">
                      <span > EN - English</span>
                    </Typography>} />
                </span>
                <span>
                  <FormControlLabel
                    value="it"
                    control={<Radio />}
                    // label={<Typography
                    //   variant="h5">
                    //   <img alt="flag Italy" style={{ width: 32, paddingRight: 10 }}
                    //     src="/images/lang/Italy.png" />
                    //   <span >Italiano</span>
                    // </Typography>}
                    label={<Typography
                      variant="h5">
                      <span >IT - Italiano</span>
                    </Typography>}
                  />
                </span>
                <span>
                  <FormControlLabel
                    value="gr"
                    control={<Radio />}
                    // label={<Typography
                    //   variant="h5">
                    //   <img alt="flag Greece"
                    //     style={{ width: 32, paddingRight: 10 }}
                    //     src="/images/lang/Greece.png" />
                    //   <span >Ελληνικά</span>
                    // </Typography>} />
                    label={<Typography
                      variant="h5">
                      <span >GR - Ελληνικά</span>
                    </Typography>} />
                </span>
              </RadioGroup>

            </Box>


          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="small"
              onClick={this.onclose}
              color="primary"
            >
              <Typography >{t('main.btn_cancel')}</Typography>
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => this.changeLanguage()}

              color="primary">

              <Typography>{t('main.btn_ok')}</Typography>

            </Button>
          </DialogActions>
        </Dialog>
      </Suspense>
    )
  }
};

export default withTranslation()(DialogLanguage);
