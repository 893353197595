import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import "./DialogReportDetail.css";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PreviewIcon from '@mui/icons-material/Preview';
import services from "../../services/services.json";
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import {
  postService, deleteService
} from "../../services/services";

const renderVideo = (item) => {
  return (
    <div className="video-wrapper">
      <iframe
        width="100%"
        height="480px"
        src={item.embedUrl}
        title="ex"
      />
    </div>
  );
};



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


class DialogReportDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // hashtags: [],
      openDialog: false,
      openDialogDistance: false,
      openDialogNodeBB: false,
      openDialogConfirm: false,
      positionenabled: false,
      itemDetail: this.props.itemDetail,
      lat: 0,
      lng: 0,
      url: "",
      images: []
    };

  }

  componentDidMount() {

  }

  componentDidUpdate(propsPrecedenti) {
    // Utilizzo tipico (non dimenticarti di comparare le props):
    if (this.props.itemDetail !== this.state.itemDetail) {
      // console.log(this.props.itemDetail)
      // console.log("TYPE :" + this.props.itemDetail.resources[0].type);
      // 
      this.setState({ itemDetail: this.props.itemDetail })
      this.urlResource();
    }
  }





  urlResource = () => {
    // console.log("n. immagini: " + this.props.itemDetail.resources.length);
    let images = [];
    let url = "";

    for (var i = 0; i < this.props.itemDetail.resources.length; i++) {
      let userid = window.localStorage.getItem("userid");
      // let domain = window.localStorage.getItem("currentdomain");
      // let app = window.localStorage.getItem("currentapp");
      let domain = this.props.itemDetail.domain;
      let app = 'greenscent';
      url = process.env.REACT_APP_DOMAIN + services.urlResource_GET;
      url = url.replace("{APPNAME}", app);
      url = url.replace("{DOMAIN}", domain);
      url = url.replace("{USERID}", userid);

      if (this.props.itemDetail.resources[i]?.type === "image") {
        url = url + "&cover=false&medialan=en&mediares=first&" + "id=" + this.props.itemDetail.resources[i]?._id; //eslint-disable-line

        var objImg = {
          original: url,
          thumbnail: url,
        }
        images.push(objImg);
        // console.log("URL Img:" + url);
      } else {
        let vidUrl = url + "&cover=true&medialan=en&mediares=first&" + "id=" + this.props.itemDetail.resources[i]?._id; //eslint-disable-line
        let embedUrl = url + "&cover=false&medialan=en&mediares=first&" + "id=" + this.props.itemDetail.resources[i]?._id; //eslint-disable-line
        // console.log("URL   " + vidUrl)
        // console.log("embedURL   " + embedUrl)

        var objVid = {
          embedUrl: embedUrl,
          original: '',
          thumbnail: vidUrl,
          renderItem: renderVideo.bind(this)
        }
        images.push(objVid);
      }

    }
    this.setState({ images: images },
      // () => console.log("url risorsa: " + JSON.stringify(this.state.images))
    );
  }



  onclose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.props.close();
    // this.setState({ images: [] }); // BUG su Dialog. Se chiudo e clicco sullo stesso popup mi perdo l'[]
  }

  openDialogImages = () => {
    this.setState({ openDialog: true });
  };
  closeDialog = () => {
    this.setState({ openDialog: false });
  };

  openDialogNodeBB = (item) => {
    this.setState({ openDialogNodeBB: true });
  }
  closeDialogNodeBB = () => {
    this.setState({ openDialogNodeBB: false });
  };

  openDialogDistance = (item) => {
    this.setState({ openDialogDistance: true });
  }
  closeDialogDistance = () => {
    this.setState({ openDialogDistance: false });
  };

  openDialogConfirm = (item) => {
    this.setState({ openDialogConfirm: true });
  }
  closeDialogConfirm = () => {
    this.setState({ openDialogConfirm: false });
  };

  getUTCData = (data) => {
    try {
      return new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: "UTC",
        timeZoneName: "short",
      }).format(new Date(data));
    } catch (error) {
      return "";
      // console.log(error);
    }
  };

  openItemMap = () => {
    let latItem = this.state.itemDetail.location.coordinates[1] + ",";
    let lngItem = this.state.itemDetail.location.coordinates[0];

    let lat = this.state.lat + ",";
    let lng = this.state.lng;
    var mapLocationUrl =
      "https://maps.google.com/maps?saddr=" +
      lat +
      lng +
      "&daddr=" +
      latItem +
      lngItem;
    // }
    // console.log("mapLocationUrl:" + mapLocationUrl);
    window.open(encodeURI(mapLocationUrl), "_self", "_system", "location=no");
    // this.setState({urlDistance: mapLocationUrl});
  };

  openNodeBB = () => {
    // console.log(this.state.itemDetail.nodebb.response)
    // console.log(this.state.itemDetail.nodebb.response.tid)

    let url = "";
    let nodeBBTid = this.state.itemDetail.nodebb.response?.tid
    // let userLogin = window.localStorage.getItem("userlogged");
    let username = window.localStorage.getItem("name");
    let language = window.localStorage.getItem("language");
    let status = this.state.itemDetail.status;

    // console.log(username, status, nodeBBTid, language)


    // &noBottom=1&noTop=1 //eliminano header e footer di node BB dalla dialog

    let urlA = "https://cbroker-ba2know.eng.it/BB/sessioneNEW.php?langID=" + language + "&noBottom=1&noTop=1&username=";
    let urlB = "&mod[]=Verify_user_login&mod[]=get_topic_data&topicID="

    if (status === "APPROVED") {
      if (username !== "") {

        url = urlA + username + urlB + nodeBBTid;

      } else {
        url = urlA + "Guest" + urlB + nodeBBTid;
      }
    }
    if (status === "SOLVED") {

      url = urlA + "Guest" + urlB + nodeBBTid;

    }
    if (!username) {

      url = urlA + "Guest" + urlB + nodeBBTid;

    }



    // console.log("URL" + url);
    this.setState({ url: url });

    this.openDialogNodeBB();

    // window.open(encodeURI(url), "_self", "_system", "location=no");


  };

  getSeverity = () => {

    let sev = this.state.itemDetail.gravity;
    // console.log("severity" + sev)
    let arr = [];


    if (sev === 0) {
      arr.push(<FiberManualRecordIcon color="error" style={{ fontSize: "1.3rem}" }} />);
    }

    if (sev === 50) {
      arr.push(<FiberManualRecordIcon color="error" style={{ fontSize: "1.3rem}" }} />);
      arr.push(<FiberManualRecordIcon color="error" style={{ fontSize: "1.3rem}" }} />);
    }

    if (sev === 100) {
      arr.push(<FiberManualRecordIcon color="error" style={{ fontSize: "1.3rem}" }} />);
      arr.push(<FiberManualRecordIcon color="error" style={{ fontSize: "1.3rem}" }} />);
      arr.push(<FiberManualRecordIcon color="error" style={{ fontSize: "1.3rem}" }} />);

    }


    /*for (let i = 0; i < sev; i++) {
      arr.push(<FiberManualRecordIcon />);
    }*/
    // console.log("arr" + arr.length)

    return arr;
  }



  changeStatus = (note) => {
    // let self=this;
    let app = window.localStorage.getItem("currentapp");
    let domain = window.localStorage.getItem("currentdomain");

    let params = {
      status: "SOLVED",
      note: note,
      warningid: this.state.itemDetail._id,
      domain: domain,
      appname: app
    }

    postService(process.env.REACT_APP_DOMAIN + services.changestatus_POST, params,
      "", "",
      function (err, result) {

        if (err) {
          // console.log("changeStatus Error:  " + err);
        }

        else if (result.status === 200) {
          // console.log("changeStatus ok");
          // console.log(JSON.stringify(result.response));

          //exit dalla Dialog
          // self.props.close();
          let url = "";

          url = "web-app/?app=" + app + "&domain=" + domain;
          //window.open(encodeURI(url), "_self", "_system", "location=no");     
          window.location.href = ('/' + url)
        }

      });



  }



  removeReport = () => {
    let app = window.localStorage.getItem("currentapp");
    let domain = window.localStorage.getItem("currentdomain");

    let data = {
      appname: app,
      domain: domain,
      warningid: this.state.itemDetail._id
    }

    deleteService(process.env.REACT_APP_DOMAIN + services.removereport_DELETE, data,
      "",
      function (err, result) {

        if (err) {
          // console.log("removeReport Error:  " + err);
        }

        else if (result.status === 204) {
          // console.log("removeReport ok");
          // console.log(JSON.stringify(result));
          // console.log(JSON.stringify(result.response));


          let url = "";

          url = "web-app/?app=" + app + "&domain=" + domain;
          //window.open(encodeURI(url), "_self", "_system", "location=no");     
          window.location.href = ('/' + url)

        }

      });


  }

  getNodeBBAccess = () => {
    let flag = true;
    let status = this.state.itemDetail.status;

    //console.log(status);
    if (status === "APPROVED" || status === "SOLVED") {
      flag = false;
    } else {
      //console.log(flag);
    }


    return flag;
  }


  render() {
    const { t } = this.props;
    let lng = window.localStorage.getItem("i18nextLng");
    return (
      <Suspense fallback={(<div>Loading</div>)}>
        <Dialog
          maxWidth={600}
          open={this.props.open}
          onClose={this.onclose}
          TransitionComponent={Transition}>
          <DialogTitle color="primary" style={{ backgroundColor: "#004c43", height: "60px" }}>
            <PreviewIcon
              style={{ color: "#FFF", fontSize: "40px" }}
            />
            <span style={{
              color: "#FFF",
              padding: 5,
              position: 'fixed',
            }}>
              OVERVIEW
            </span>
          </DialogTitle>
          <DialogContent
            // sx={{ p: 0, overflow: "hidden" }}
            style={{ backgroundColor: "#8db5b585" }}
          >

            <Box
              width={968}
              height={490}
              marginTop="10px"
              display="flex"
              flexWrap="wrap"
            // border="1px solid #008080"
            // boxSizing="border-box"
            >
              <Box
                display="block"
                // width={{ xs: 1, sm: 1 / 2, md: 1 / 4 }}
                width={{ sm: 1 / 2 }}
              // mb={{ sm: 0 }}
              // border={{
              //   xs: '3px solid yellow',
              //   sm: '2px solid orange',
              //   md: '1px solid red'
              // }}
              // p="15px"
              // borderRadius="5px"
              // boxSizing="border-box"
              >

                {/* <div className="header">
                  <h1>TITOLO DA VALUTARE</h1>
                </div> */}

                <div className="clearfix">

                  <div className="column content">
                    <Typography variant="h1 button"
                      color="primary"
                      style={{ fontWeight: 900 }}
                    >
                      {this.state.itemDetail.name}
                    </Typography>
                    <img style={{
                      height: "30vh",
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                      borderRadius: "10px"
                    }}
                      alt=""
                      src={this.props.cover}
                    />
                  </div>

                  <div className="column menu">
                    <ul>
                      {
                        this.state.itemDetail.tags?.map((item, i) => (
                          <li>{this.state.itemDetail.tags[i].name[lng]}</li>
                        ))}
                    </ul>
                  </div>


                </div>

                {/* <div className="footer">
                  <p>FOOTER NON IN USO</p>
                </div> */}

                <div className="scroll-text"
                  style={{ backgroundColor: "#afcece" }}
                >
                  <Typography color="primary" level="body2">
                    {this.state.itemDetail.description}
                  </Typography>
                </div>


              </Box>

              <Box
                // display="flex"
                width={{ sm: 1 / 2 }}
              // mb={{ sm: 0 }}
              // border={{
              //   xs: '3px solid yellow',
              //   sm: '2px solid orange',
              //   md: '1px solid red'
              // }}
              // p="15px"
              // borderRadius="5px"
              // boxSizing="border-box"
              >

                <div className="gallery">
                  {
                    this.state.images?.map((item, i) => (
                      <>
                        {this.props.itemDetail?.resources[i]?.type === "image" ?

                          <a href={this.state.images[i].thumbnail} target="_blank" rel="noreferrer">
                            <img src={this.state.images[i].thumbnail} alt="img_report" />
                          </a>
                          :
                          <video controls style={{
                            width: "100%",
                            maxHeight: "180px",
                            objectFit: "cover",
                            borderRadius: "10px"
                          }}>
                            <source src={this.state.images[i].embedUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        }
                      </>
                    ))}
                </div>

              </Box>

              <Box
                display="flex"
                width={{ sm: 1 / 2 }}
                // mb={{ sm: 0 }}
                // border={{
                //   xs: '3px solid yellow',
                //   sm: '2px solid orange',
                //   md: '1px solid red'
                // }}
                p="10px"
              // borderRadius="5px"
              // boxSizing="border-box"
              >

                <div
                // className="columnR"
                >
                  {this.props.avatar !== undefined && this.props.avatar !== "" && this.props.avatar !== null ?
                    <img alt="" className="img-user-dialog" src={this.props.avatar} />
                    :
                    <AccountCircleIcon style={{ fontSize: "50" }} />
                  }
                </div>

                <div
                  className="columnLeft"
                >
                  {/* <Typography variant="overline" display="block" gutterBottom>
                    <span>{this.props.nickname}</span>
                  </Typography> */}
                  <Typography color="primary" level="body2" style={{ fontWeight: 900 }}>
                    {this.props.nickname}
                  </Typography>

                  <div className="footer">

                    <Typography color="primary" level="body2">
                      <span style={{ fontWeight: 900 }}>{t('main.inserted')}</span><span>{this.getUTCData(this.state.itemDetail.createdAt)}</span>
                      <br />
                      <span style={{ fontWeight: 900 }}>{t('main.lastupd')}</span><span>{this.getUTCData(this.state.itemDetail.modifiedAt)}</span>
                      <br />
                      <span style={{ fontWeight: 900 }}>Status: </span><span>{this.state.itemDetail.status}</span>
                    </Typography>

                  </div>
                </div>

              </Box>

              <Box
                // display="flex"
                width={{ sm: 1 / 2 }}
                // mb={{ sm: 0 }}
                border={{
                  // xs: '3px solid yellow',
                  // sm: '2px solid orange',
                  // md: '1px solid red'
                }}
                // p="15px"
                // borderRadius="5px"
                // boxSizing="border-box"
                style={{ marginTop: "75px" }}
              >

                <Typography color="primary" level="body2">
                  <span style={{ fontWeight: 900 }}>{t('main.lbl_domain')}: </span>{this.props.itemDetail.shortname}
                </Typography>
              </Box>



            </Box>






          </DialogContent>

          {/* <div style={{ position: "fixed", top: 'auto', bottom: 60, textAlign: "justify" }}> */}




          {/* <Box sx={{ display: 'flex', justifyContent: "space-around" }}>
            <IconButton
              color="primary"
              aria-label="all picture"
              component="label"
              onClick={() => {
                this.openDialogImages();
              }}
            >
              <Badge badgeContent={this.props.badge}
                color="secondary"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}>
                <FilterIcon style={{ fontSize: "2.5rem" }} />
              </Badge>
            </IconButton>


            <IconButton
              // onClick={() => { this.openDialogNodeBB() }}
              onClick={() => { this.openNodeBB() }}
              color="primary"
              aria-label="all messages"
              component="label"
              disabled={this.getNodeBBAccess()}
            >
              <Badge
                // badgeContent={16} 
                color="secondary"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <ChatBubbleOutlineIcon style={{ fontSize: "2.5rem" }} />
              </Badge>
            </IconButton>




            <IconButton
              disabled={this.state.positionenabled === true ? false : true}
              color="primary"
              aria-label="all messages"
              component="label">


              <img
                onClick={() => { this.openItemMap() }}
                src={this.state.positionenabled === true ? "../images/itinerary-primary.png" : "../images/itinerary-disabled-red.png"}
                alt="background"
                style={{
                  width: "50px",
                  // marginLeft: "16px",
                  // position: "relative",
                  // top: "5px",
                  // color: "primary",
                }}
              >
              </img>

              <span className='badge-km'
                style={{
                  visibility:
                    this.state.positionenabled === true
                      ? "visible"
                      : "hidden",
                }}
              >
                {this.props.kilometres} km
              </span>
            </IconButton>

          </Box> */}

          <DialogActions style={{ display: "flex", justifyContent: "", backgroundColor: "#8db5b585" }}>
            <span>&nbsp;&nbsp;&nbsp;</span>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={this.onclose}
            >
              <Typography >{t('main.btn_close')}</Typography>
            </Button>
          </DialogActions>



          {/* <DialogImages
          open={this.state.openDialog}
          close={() => this.closeDialog()}
          itemDetail={this.state.itemDetail}
        />


        <DialogNodeBB
          open={this.state.openDialogNodeBB}
          close={() => this.closeDialogNodeBB()}
          NodeBBUrl={this.state.url}
        />

        <DialogMapDistance
          open={this.state.openDialogDistance}
          close={() => this.closeDialogDistance()}
          itemDetail={this.state.itemDetail}
        />

        <DialogConfirm
          open={this.state.openDialogConfirm}
          close={() => this.closeDialogConfirm()}
          itemDetail={this.state.itemDetail}
          callbackC={this.changeStatus}
          callbackR={this.removeReport}
        /> */}

        </Dialog>

      </Suspense >
    )
  }
};

export default withTranslation()(DialogReportDetail);