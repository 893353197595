import L from "leaflet";

export default L.icon({
  iconSize: [30, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  // shadowSize: [41, 41],
  shadowAnchor: null,
  iconUrl: "/images/marker-icon-red.png",
  // iconRetinaUrl:  "/images/marker-icon-red.png",
  // shadowUrl: "/images/marker-icon-red.png",
  // className: 'marker-svg'
});
