import React, { Component } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, } from 'recharts';
import { withTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import moment from 'moment';
import DialogReportDetail from './Dialog/DialogReportDetail';

import services from "../services/services.json";



const CustomTooltip = ({ active, payload, format, item, getUrl}) => {

  if (active && payload && payload.length) {

  // console.log(payload)
   // console.log(payload[0].value);
   // console.log(payload[1].value);
    
    let value = format(payload[0].value);
    
     let report= item (payload[0].payload.id);
  //  console.log(JSON.stringify(report));

    return (
      <div style={{ backgroundColor: "#CCC", textAlign: "justify" }} >
       <span style={{ fontSize: 12, color: '#0000008a' }}>{report[0].name}</span> 
        <br />
        <span style={{ fontSize: 12, color: '#0000008a' }}>Domain: </span>
        <span style={{ fontSize: 12, color: '#0000008a' }}>{report[0].shortname}</span> 
        <br />
        <span style={{ fontSize: 12, color: '#0000008a' }}>User: </span>
        <span style={{ fontSize: 12, color: '#0000008a' }}>{report[0].ownerdetails[0].name}</span> 
        <br />
        <img style={{
                    height: "150px",
                    width: "230px",
                    objectFit: "cover",
                    objectPosition: "center"
                
                  }}
                    alt=""
                    src={getUrl(report[0])}
                  />
                   <br />
        <span style={{ fontSize: 12, color: '#0000008a' }}>Created at: </span>
        <span style={{ fontSize: 12, color: '#000000de' }}>{value}</span>
        <br />
        <span style={{ fontSize: 12, color: '#0000008a' }}>{`Away from you :`} </span>
        <span style={{ fontSize: 12, color: '#000000de' }}>{`${payload[1].value} km`}</span>
      
      </div>

    
    
        
    );
  }
 
 
  return null;
};



class TimelineChart extends Component {

  constructor(props) {
    super(props);

    this.child = React.createRef();

    this.state = {
      dati: this.props.items,
      array: [],
      valueFromDatePicker: null,
      valueToDatePicker: null,
      lat: 50.83885,
      lng: 4.37513,
      valueSlider: ([-40, 40]), 
      report:[],
      openDialogDetail:false,
      cover: "",
      avatar: "",
      nickname: ""
    }
  }

  componentDidMount() {
    // console.log(JSON.stringify(this.state.dati)); 
    this.getLocationUser();
    this.getCoordinates();

  }

  getLocationUser = () => {
    if (navigator.geolocation) {

      navigator.geolocation.getCurrentPosition(this.showPosition, this.errorPosition);
    } else {
      // console.log("Geolocation is not supported by this browser.");//all'inizio e se non supportato bisogna posizionarsi a Roma
    }
  }

  errorPosition = (error) => {
    if (error.code === error.PERMISSION_DENIED) {
      // console.log(error);
    }
  }

  showPosition = (position) => {
    this.setState({ lat: position.coords.latitude });
    this.setState({ lng: position.coords.longitude });

  }

  getCoordinates = () => {
    // let listObj = [{ x: [], y: [], id: [] }]
    let listObj = [];

    for (let i = 0; i < this.state.dati.length; i++) {

      listObj.push({
        x: this.state.dati[i].createdAt,
        y: this.getDistance(this.state.dati[i]),
        id: this.state.dati[i]._id.toString()
      });
    }

    this.setState({ array: listObj },
      //  () => console.log(JSON.stringify(this.state.array))
    )

  }

  getDistance = (item) => {

    let R = 6371e3; // metres
    let φ1 = this.state.lat * Math.PI / 180; // φ, λ in radians
    let φ2 = item.location.coordinates[1] * Math.PI / 180;
    let Δφ = (item.location.coordinates[1] - this.state.lat) * Math.PI / 180;
    let Δλ = (item.location.coordinates[0] - this.state.lng) * Math.PI / 180;

    let a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    let d = R * c; // in metres

    let KM = (Math.round(d) / 1000);
    // return d.toFixed(0)/1000;
    return KM
  }


  formatXAxis = (tickItem) => {
    return moment(tickItem).format('DD-MM-YYYY');
  }

   

  filter = (from, to) => {
    let arr = [];
    //  console.log(from,to)
    if (from !== null && to !== null) {
      let datafrom = from.getTime();
      // console.log(datafrom);
      //   if (to!==null){
      let datato = to.getTime();
      // console.log(datato);
      for (let i = 0; i < this.state.dati.length; i++) {
        // console.log(this.state.dati[i].createdAt)
        if (this.state.dati[i].createdAt <= datato
          && this.state.dati[i].createdAt >= datafrom) {
          arr.push(this.state.dati[i]);
          //  console.log(JSON.stringify(arr.length));
        }
      }

      // let listObj = [{ x: [], y: [], id: [] }]
      let listObj = [];

      if (arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          listObj.push({
            x: arr[i].createdAt,
            y: this.getDistance(arr[i]),
            id: arr[i]._id.toString()
          });
        }
      } else {
        console.log("no reports found")
      }
      this.setState({ array: listObj })
      //  }
    }
  }


  setFromValueDatePicker = (newValue) => {
    let from = newValue.$d;
    this.setState({ valueFromDatePicker: from },
      // () => console.log(this.state.valueFromDatePicker),
      this.filter(from, this.state.valueToDatePicker))
  }


  setToValueDatePicker = (newValue) => {
    let to = newValue.$d;
    this.setState({ valueToDatePicker: to },
      // () => console.log(this.state.valueToDatePicker),
      this.filter(this.state.valueFromDatePicker, to))
  }

  handleClick =(event, payload)=>{
    console.log( "EVENT"+ JSON.stringify(event))
    console.log( payload)
    console.log( event.id)
    
    if(event && event.id){

                let report = this.getItem(event.id)

                this.setState({ report: report[0] }, 
                  ()=>console.log("REPORT"+JSON.stringify(this.state.report)))
                 this.getCoverItemDetail(report[0]);
                 this.getAvatarItemDetail(report[0]);
                 this.getNicknameItemDetail(report[0]);
                 this.open()
     }
    //else{
    //   this.setState({ report: [] })
    // }

    
  }


  getCoverItemDetail = (item) => {
    let cover = this.getUrl(item);
    this.setState({ cover: cover })
  }
  getAvatarItemDetail = (item) => {
    let avatar = item.ownerdetails[0].avatar;
    this.setState({ avatar: avatar })
  }
  getNicknameItemDetail = (item) => {
    let nickname = item.ownerdetails[0].name;
    this.setState({ nickname: nickname })
  }

  open = () => {
    this.setState({ openDialogDetail: true });
  }

  closeDialogDetail = () => {
    this.setState({ openDialogDetail: false });
  };

  getUrl = (item) => {
    let url = "";
    let userid = window.localStorage.getItem("userid");
    let domain = item.domain;
    let app = 'greenscent';
    url = process.env.REACT_APP_DOMAIN + services.urlResource_GET;
    url = url.replace("{APPNAME}", app);
    url = url.replace("{DOMAIN}", domain);
    url = url.replace("{USERID}", userid);

    if (item.coverobj?.type === "image") {
      //sostituire  item.resources[0]?._id  con item.coverobj?._id
      url = url + "&cover=false&medialan=en&mediares=first&" + "id=" + item.coverobj?._id; //eslint-disable-line

    } else {
      url = url + "&cover=true&medialan=en&mediares=first&" + "id=" + item.coverobj?._id; //eslint-disable-line
    }
    return url;
  }

  getItem = (id) => { 
   
    let arr=[];
   for(let i=0; i<this.props.items.length; i++){
      if (id.toString().includes(this.props.items[i]._id)){
        arr.push(this.props.items[i]);
      }else{
      //  console.log("non trovato")
      }
   }
   return arr;
  }



  render() {
    const { t } = this.props;
    // let lng = window.localStorage.getItem("i18nextLng");  

    return (
      <>

        <Box style={{
          width: '97vw',
          backgroundColor: "#CCCCCCb0",
        }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box style={{ width: 'auto', display: "inline-flex" }}>
              <div components={['DatePicker']} style={{ margin: 10 }}>
                <DatePicker label= {t('main.lbl_from')} 
                  format="DD/MM/YYYY"
                  value={this.state.valueFromDatePicker}
                  onChange={(newValue) => this.setFromValueDatePicker(newValue)}
                />
              </div>

              <div components={['DatePicker']} style={{ margin: 10 }}>
                <DatePicker label= {t('main.lbl_to')} 
                  format="DD/MM/YYYY"
                  value={this.state.valueToDatePicker}
                  onChange={(newValue) => this.setToValueDatePicker(newValue)} />
              </div>
            </Box>
          </LocalizationProvider>

          <div style={{
            width: '100vw',
            // backgroundColor:"#e883198a" 
          }}>
            <ResponsiveContainer width="95%" height={310}>

              {!this.state.array.length > 0 ?
                <div style={{ textAlign: 'center' }}>
                  <b style={{ color: "#464646" }}>{t('main.lbl_norepfound')} </b>
                </div> :
                <ScatterChart
                  // width={930}
                  // height={350}
                  margin={{
                    top: 30,
                    right: 10,
                    bottom: 10,
                    left: 10,
                  }}
                  type="bump"
                  stroke="#008080"
               
                // onClick={(event)=>{ alert('alert'); }}
                >
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis
                    dataKey={'x'}
                    domain={['auto', 'auto']}
                    tick={{ fontSize: 12 }}
                    tickFormatter={(this.formatXAxis)}
                    type="number"
                    tickMargin={12}
                  // label={{ value: "Range date", position: "outsideLeft" }}
                  />
                  <YAxis
                    dataKey="y"
                    type="number"
                    // label={{ value: "Distance in km", angle: -90, position: "outsideBottom"}}
                    unit=" km"
                    tick={{ fontSize: 12 }}
                    tickMargin={12}
                    width={70}
                  />
                  <Tooltip
                    cursor={{ strokeDasharray: '3 3' }}
                    content={<CustomTooltip 
                      format={this.formatXAxis} 
                      item={this.getItem}
                      getUrl={this.getUrl}
                      />}
                  />
                  <Legend />
                
                  <Scatter
                    name="Reports found"
                    data={this.state.array}
                    fill="#348acc"
                    legendType="none"
                    onClick={(event, payload) => this.handleClick(event, payload)} 
                  />
                </ScatterChart>
              }
              

            </ResponsiveContainer>

          </div>
        </Box>
    
        <DialogReportDetail
          open={this.state.openDialogDetail}
          close={() => this.closeDialogDetail()}
          itemDetail={this.state.report}
       
          cover={this.state.cover}
          avatar={this.state.avatar}
          nickname={this.state.nickname}
         
        /> 
      </>
    )
  }
}
export default withTranslation()(TimelineChart);
