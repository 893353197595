import React, { Component } from 'react';
import Leaflet4Res from './Leaflet4Res';
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import services from "../services/services.json";
import { getService } from "../services/services";
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import PhotoIcon from '@mui/icons-material/Photo';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import DescriptionIcon from '@mui/icons-material/Description';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    color: '#FFF',
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
    '&.Mui-selected': {
      border: '3px dotted #FFF!important',
    },
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.5),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.5),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));


class MapResources extends Component {

  constructor(props) {
    super(props);

    this.child = React.createRef();


    this.state = {
      itemsView: [],
      resources: [],
      originalResources: [],
      search: [],
      selectedTypes: []
    }
  }

  componentDidMount() {
    // console.log(this.props.items);
    // this.filter();
    this.getResources();
  }

  componentDidUpdate(propsPrecedenti) {

  }

  getResources = () => {

    let appname = "greenscent";
    let dest = "resource";
    let self = this;
    let ser = process.env.REACT_APP_DOMAIN + services.resources_GET;
    ser = ser.replace("{APPNAME}", appname);
    ser = ser.replace("{DEST}", dest);
    // console.log(ser);


    getService(ser, "", function (err, result) {

      if (err) {
        // console.log("err " + err);
        return;
      }
      if (result.status === 200) {
        // console.log("Get Resource ok");
        // console.log("get Resource list" + JSON.stringify(result.response));
        // console.log("resources: " + result.response.length);

        self.setState({
          resources: result.response,
          originalResources: result.response

        });
        return;
      }
      if (result.status === 400) {
        //console.log(result.status)
        self.setState({

          resources: [], originalResources: []

        });
        //array vuoto
      }

      return;
    });
  };


  getDistance = (item) => {


    let R = 6371e3; // metres
    let φ1 = this.state.lat * Math.PI / 180; // φ, λ in radians
    let φ2 = item.location.coordinates[1] * Math.PI / 180;
    let Δφ = (item.location.coordinates[1] - this.state.lat) * Math.PI / 180;
    let Δλ = (item.location.coordinates[0] - this.state.lng) * Math.PI / 180;

    let a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    let d = R * c; // in metres

    let KM = (Math.round(d) / 1000);
    // return d.toFixed(0)/1000;
    return KM
  }





  truncate = (str, max) => {
    return str.length > max ? str.substr(0, max - 1) + '…' : str;
  }



  viewArrayMarker = (y) => {
    let arrapp = []

    for (let i = 0; i < y.length; i++) {


      for (let j = 0; j < this.state.itemsView.length; j++) {
        //console.log(this.state.itemsView[j]._id, y[i])

        if (this.state.itemsView[j]._id === y[i]) {

          arrapp.push(this.state.itemsView[j])
        }
      }

    }

    this.setState({
      itemsViewInBound: arrapp,
      activeStep: 0,
      //id: arrapp[0]._id  
      id: ""
    },
      // () => console.log("itemsViewInBound after :" + JSON.stringify(this.state.itemsViewInBound.length))
    );



    if (arrapp.length > 0) {
      this.setState({ id: arrapp[0]._id })
    }
    else {
      this.setState({ id: "" })
    }

  }

  searchInput = (event) => {
    let listObj = [];

    listObj = this.state.originalResources;

    if (this.state.selectedTypes.length > 0) {
      listObj = this.getArrayFiltrato();
    }

    if (event !== "") {

      let filtered = listObj.filter(
        function (el, index, arr) {
          let found = false;

          if (el.name.toUpperCase().indexOf(event.toUpperCase()) !== -1) {
            found = true;
          }
          return found;

        }
      );

      this.setState({ resources: filtered, search: filtered },
        // () => console.log("filtered" + JSON.stringify(filtered.length))
        );
    } else {
      this.setState({ resources: listObj, search: [] },
        // () => console.log("resources no filtered" + JSON.stringify(this.state.resources.length))
      );
    }

  };


  getArrayFiltrato = () => {
    // let lng = window.localStorage.getItem("i18nextLng");
    let array_filtered = [];
    let listObj = [];

    listObj = this.state.originalResources;

    //console.log("NEWVALUE "+newValue)
    if (this.state.selectedTypes.length > 0) {
      //  console.log("----------------------------------------------");
      for (let i = 0; i < listObj.length; i++) {

        if ((this.state.selectedTypes).includes(listObj[i].type)) {
          //elimina i doppioni
          if (!array_filtered.includes(listObj[i]))
            array_filtered.push(listObj[i]);
        }
        // }
      }
    }
    return array_filtered;
  }


  selectTypes = (newValue) => {
   
    // console.log("TYPE" + newValue);
    this.setState({ selectedTypes: newValue }
    //   , () => console.log("selectedTypes " + JSON.stringify(this.state.selectedTypes))
    );
    let newValue4Cloud=[];
    for (let i = 0; i < newValue.length; i++) {
      let type=newValue[i];
      let typecloud = newValue[i].concat("cloud");
      newValue4Cloud.push(type);
      newValue4Cloud.push(typecloud);      
    }  
    // console.log("newValue4Cloud " + JSON.stringify(newValue4Cloud));
    this.filterItemsByTypes(newValue4Cloud)
  }


  filterItemsByTypes = (newValue) => {
    // let lng = window.localStorage.getItem("i18nextLng");
    let array_filtered = [];
    let listObj = [];
    listObj = this.state.originalResources;

    if (this.state.search.length > 0) {
      listObj = this.state.search;
    }

    //console.log("NEWVALUE "+newValue)
    if (newValue.length > 0) {
      //  console.log("----------------------------------------------");
      for (let i = 0; i < listObj.length; i++) {
        if ((newValue).includes(listObj[i].type)) {
          //elimina i doppioni
          if (!array_filtered.includes(listObj[i]))
            array_filtered.push(listObj[i]);
        }
        // }
      }
      // console.log(JSON.stringify("array_filtered: " + array_filtered.length));
      this.setState({ resources: array_filtered },
        () => console.log("resources filtered" + JSON.stringify(this.state.resources.length))
      )
    }
    else this.setState({ resources: listObj },
      //  () => console.log("resources no filtered" + JSON.stringify(this.state.resources.length))
    )
  }



  render() {
    const { t } = this.props;
    // let lng = window.localStorage.getItem("i18nextLng");

    return (
      <>

        <Leaflet4Res
          items={this.state.resources}
          viewArrayMarker={this.viewArrayMarker}
          id={this.state.id}
          getActiveStep={this.getActiveStep}
        />


        <Box sx={{ flexGrow: 1 }} >
          <AppBar position="static" sx={{ mt: 4 }}>
            <Toolbar>

              <Search style={{ backgroundColor: '#008080' }}>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  onChange={(e) => this.searchInput(e.target.value)}
                  placeholder={t('main.btn_search')}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </Search>

              <Box sx={{ flexGrow: 1 }} />

              <StyledToggleButtonGroup
                size="large"
                // exclusive
                // style={{ backgroundColor: '#00808090'}}
                value={this.state.selectedTypes}
                onChange={(event, newValue) => { this.selectTypes(newValue) }}
              >
                <ToggleButton
                  value="image"
                  variant="contained"
                  style={{ backgroundColor: '#ac3a03', width: "130px" }}
                  startIcon={<PhotoIcon />}>
                  {t('main.btn_image')}
                </ToggleButton>
                <ToggleButton
                  value="video"
                  variant="contained"
                  style={{ backgroundColor: '#24ac20', marginLeft: "30px", width: "130px" }}
                  startIcon={<PlayCircleOutlineIcon />}>
                  {t('main.btn_video')}
                </ToggleButton>
                <ToggleButton
                  value="audio"
                  variant="contained"
                  style={{ backgroundColor: '#cac428', color: '#3d3d3d', marginLeft: "30px", width: "130px" }}
                  startIcon={<AudiotrackIcon />}>
                  {t('main.btn_audio')}
                </ToggleButton>
                <ToggleButton
                  value="document"
                  variant="contained"
                  style={{ backgroundColor: '#3d3d3d', marginLeft: "30px", width: "130px" }}
                  startIcon={<DescriptionIcon />}>
                  {t('main.btn_document')}
                </ToggleButton>
              </StyledToggleButtonGroup>

            </Toolbar>
          </AppBar>
        </Box>

        <Suspense fallback={(<div>Loading</div>)}>
          {this.state.resources.length === 0 ?
            <>
              <Typography>
                <span
                  style={{
                    width: "30vw",
                    left: "32vw",
                    top: "166px",
                    fontSize: "22px",
                    color: "#004c43",
                    zIndex: 100,
                    position: "absolute",
                    backgroundColor: "#ffffff7a",
                  }}
                >
                  {t('main.alert_itemsnotfound')}
                </span>
              </Typography>
            </>
            :
            <>

              <Box sx={{
                bottom: "5%",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                position: 'absolute',
                width: "100%",
                // m:2,

                // paddingBottom:"33px",
              }}>
              </Box>
            </>
          }
        </Suspense>
      </>
    )
  }

}

//export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MapList));
export default withTranslation()(MapResources);