import React, { Component } from 'react';
import Leaflet from './Leaflet';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import "./MapList.css";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import services from "../services/services.json";
import { getService } from "../services/services";
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';

import TimelineChart from './TimelineChart';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';




const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.5),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.5),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    color: '#FFF',
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));



class MapList extends Component {

  constructor(props) {
    super(props);

    this.child = React.createRef();


    this.state = {
      itemsView: [],
      originalItems: [],
      id: "",
      hashtags: [],
      selectedHashtags: [],
      search: [],
      searchText:'',
      domains: [],
      selectedDomain:''
    }
  }

  componentDidMount() {
    // console.log(this.props.items);
    // this.filter();
    this.getResources();

    this.setState({ hashtags: this.getHashtags() });

  }

  componentDidUpdate(propsPrecedenti) {

  }

  getResources = () => {

    let appname = "greenscent";
    //let domainID = "6492c9600729558553ddf9ef";
    let self = this;
    let ser = process.env.REACT_APP_DOMAIN + services.allreportlist_GET;
    ser = ser.replace("{APPNAME}", appname);
    //ser = ser.replace("{DOMAIN}", domainID);
    // console.log(ser);


    getService(ser, "", function (err, result) {

      if (err) {
        // console.log("err " + err);
        return;
      }
      if (result.status === 200) {
        // console.log("Get ReportList ok");
        // console.log("get report list" + JSON.stringify(result.response));
         console.log("reports : " + result.response.length);

        self.setState({
          itemsView: result.response,
          originalItems: result.response,
          // progress: false
        }, () => self.getDomains ());
        return;
      }
      if (result.status === 400) {
        //console.log(result.status)
        self.setState({
          //progress: false,
          itemsView: [],
          originalItems: [],
          domains:[]
        });
        //array vuoto
      }

      return;
    });
  };


  getDomains = ()=>{
    let arr=[];
    for(let i=0; i<this.state.itemsView.length; i++){
      if (!arr.includes(this.state.itemsView[i].shortname))
         arr.push(this.state.itemsView[i].shortname)
    }
    this.setState({domains:arr})
  }


  getHashtags = () => {
    let app = "greenscent";

    var self = this;
    getService(process.env.REACT_APP_DOMAIN + services.tags_GET.replace("{APPNAME}", app), "", function (err, result) {
      if (err) {
        // console.log("hashtags_GET Error");
      } else if (result.status === 200) {
        //console.log("hashtags_GET OK");
        //console.log("response: " + JSON.stringify(result.response));
        self.setState({ hashtags: result.response },
          // () => console.log(JSON.stringify("RICEVO "+self.state.hashtags.length+" HASHTAGS"))
        );
      }
    }
    );

  }

 

  selectHashtags = (newValue) => {
    //  console.log(newValue);
    this.setState({ selectedHashtags: newValue }
      // , () => console.log("selectedHashtags " + JSON.stringify(this.state.selectedHashtags))
    );


    this.filterItemsByHashtags(newValue)

  }


  filterItemsByHashtags = (newValue) => {
    let lng = window.localStorage.getItem("i18nextLng");
    let array_filtered = [];
    let listObj = [];
    listObj = this.state.originalItems;



    if (this.state.search.length===0){
          if (this.state.selectedDomain===''||this.state.selectedDomain===null) {
            listObj = this.state.originalItems;
          }else{
            listObj= this.getArrayFilteredbyInst(this.state.originalItems);
          }
    }else{
      if (this.state.selectedDomain===''||this.state.selectedDomain===null) {
          listObj = this.state.search;
          }else{
            listObj= this.getArrayFilteredbyInst(this.getInputFilter());
          }
   
    }

    //console.log("NEWVALUE "+newValue)
    if (newValue.length > 0) {
      //  console.log("----------------------------------------------");
      for (let i = 0; i < listObj.length; i++) {
        // console.log(JSON.stringify(this.state.originalItems[i].tags));    
        for (const [key, value] of Object.entries(listObj[i].tags)) { //eslint-disable-line
          // console.log("item.tag.hashtag" + JSON.stringify(value.name[lng]));
          if ((newValue).includes(value.name[lng])) {
            //elimina i doppioni
            if (!array_filtered.includes(listObj[i]))
              array_filtered.push(listObj[i]);

          }
        }
      }
      // console.log(JSON.stringify("array_filtered: " + array_filtered.length));
      this.setState({ itemsView: array_filtered },
         () => console.log("itemsView filtered by hashtags: " + JSON.stringify(this.state.itemsView.length))
      )


    }
    else this.setState({ itemsView: listObj },
        () => console.log("itemsView zero hashtags" + JSON.stringify(this.state.itemsView.length))
    )


  }


  getArrayFilteredbyHashtag = (array) => {
    let lng = window.localStorage.getItem("i18nextLng");
    let array_filtered = [];
    let listObj = array;

    //listObj = this.state.originalItems;

    //console.log("NEWVALUE "+newValue)
    if (this.state.selectedHashtags.length > 0) {
      //  console.log("----------------------------------------------");
      for (let i = 0; i < listObj.length; i++) {
        // console.log(JSON.stringify(this.state.originalItems[i].tags));    
        for (const [key, value] of Object.entries(listObj[i].tags)) { //eslint-disable-line
          // console.log("item.tag.hashtag" + JSON.stringify(value.name[lng]));
          if ((this.state.selectedHashtags).includes(value.name[lng])) {
            //elimina i doppioni
            if (!array_filtered.includes(listObj[i]))
              array_filtered.push(listObj[i]);

          }
        }
      }

    }


    return array_filtered;


  }

  selectInstitutions = (event) => {
  //  console.log(event.target.value)
    this.setState({selectedDomain: event.target.value});

    this.filterItemsByInstitutions(event.target.value);
  };

  
  
  filterItemsByInstitutions=(domain)=>{
    console.log(domain)
    let listObj = [];
    let array_filtered=[];

    listObj = this.state.originalItems;

    if (this.state.search.length===0){
          if (this.state.selectedHashtags.length === 0) {
            listObj = this.state.originalItems;
          }else{
            listObj= this.getArrayFilteredbyHashtag(this.state.originalItems);
          }
    }else{
          if (this.state.selectedHashtags.length === 0) {
          listObj = this.state.search;
          }else{
            listObj= this.getArrayFilteredbyHashtag(this.getInputFilter());
          }
   
    }
   

   
    if (domain !== null && domain!== '') {
          for (let i = 0; i < listObj.length; i++) {
            if ((domain).includes(listObj[i].shortname)) {
              //elimina i doppioni
              if (!array_filtered.includes(listObj[i]))
                array_filtered.push(listObj[i]);
                
            }
          }
          this.setState({itemsView:array_filtered},
            () => console.log("itemsViewfilteredbyINST: " + this.state.itemsView.length)
            )
  }else{
    this.setState({itemsView:listObj},
      () => console.log("itemsViewINSTnone: " + this.state.itemsView.length))
  }
   
  }

  getArrayFilteredbyInst = (arr) => {
   // let lng = window.localStorage.getItem("i18nextLng");
    let array_filtered = [];
    let listObj = arr;

  //  listObj = this.state.originalItems;

    //console.log("NEWVALUE "+newValue)
    if (this.state.selectedDomain!=='' &&this.state.selectedDomain!==null ) {
      //  console.log("----------------------------------------------");
      for (let i = 0; i < listObj.length; i++) {
       
          if ((this.state.selectedDomain).includes(listObj[i].shortname)) {
            //elimina i doppioni
            if (!array_filtered.includes(listObj[i]))
              array_filtered.push(listObj[i]);

          }
        }
      }

    


    return array_filtered;


  }


  searchInput = (event) => {

    this.setState({searchText:event}, ()=> console.log(this.state.searchText));

    let listObj = [];

    listObj = this.state.originalItems;


    if  (this.state.selectedHashtags.length===0){
      if (this.state.selectedDomain===''||this.state.selectedDomain===null) {
        listObj = this.state.originalItems;
      }else{
        listObj= this.getArrayFilteredbyInst(this.state.originalItems);
      }
}else{
  if (this.state.selectedDomain===''||this.state.selectedDomain===null) {
      listObj = this.getArrayFilteredbyHashtag(this.state.originalItems);
      }else{
        listObj= this.getArrayFilteredbyInst(this.getArrayFilteredbyHashtag(this.state.originalItems));
      }

}

    // if (this.state.selectedHashtags.length > 0) {
    //   listObj = this.getArrayFilteredbyHashtag(this.state.originalItems);
    // }
    if (event !== "") {

      let filtered = listObj.filter(
        function (el, index, arr) {
          let found = false;

          if (el.name.toUpperCase().indexOf(event.toUpperCase()) !== -1) {
            found = true;
          }
          return found;

        }
      );

      this.setState({ itemsView: filtered, search: filtered },
         () => console.log("filtered" + JSON.stringify(filtered.length))
        );
    } else {
      this.setState({ itemsView: listObj, search: [] },
        () => console.log("itemsView" + JSON.stringify(this.state.itemsView.length))
      );
    }

  };

  getInputFilter(){
  
    let listObj = [];

    listObj = this.state.originalItems;
    
    let event = this.state.searchText;
    if ( event!== "") {

      let filtered = listObj.filter(
        function (el, index, arr) {
          let found = false;

          if (el.name.toUpperCase().indexOf(event.toUpperCase()) !== -1) {
            found = true;
          }
          return found;

        }
      );
      
  return filtered;
  }
}



  // filter = () => {
  //   let arr = this.props.items;
  //   let arr1 = [];
  //   // console.log("arrayiniziale" + JSON.stringify(arr));

  //   for (let i = 0; i < arr.length; i++) {
  //     if (arr[i].status === "APPROVED" || arr[i].status === "SOLVED") {
  //       arr1.push(arr[i]);

  //     }
  //     this.setState({ itemsView: arr1 },
  //       //  () => console.log("array con solo solved" + this.state.itemsView)
  //     );

  //   }
  // }

  viewArrayMarker = (y) => {
    let arrapp = []

    for (let i = 0; i < y.length; i++) {


      for (let j = 0; j < this.state.itemsView.length; j++) {
        //console.log(this.state.itemsView[j]._id, y[i])

        if (this.state.itemsView[j]._id === y[i]) {

          arrapp.push(this.state.itemsView[j])
        }
      }

    }

    this.setState({
      itemsViewInBound: arrapp,
      activeStep: 0,
      //id: arrapp[0]._id  
      id: ""
    },
      // () => console.log("itemsViewInBound after :" + JSON.stringify(this.state.itemsViewInBound.length))
    );



    if (arrapp.length > 0) {
      this.setState({ id: arrapp[0]._id })
    }
    else {
      this.setState({ id: "" })
    }

  }

 

 



  render() {
    const { t } = this.props;
    let lng = window.localStorage.getItem("i18nextLng");


    return (
      <>

        <Leaflet
          items={this.state.itemsView}
          viewArrayMarker={this.viewArrayMarker}
          id={this.state.id}
          getActiveStep={this.getActiveStep}
        />

        <Box sx={{ flexGrow: 1, pl: 3 }} >
          <AppBar position="static" sx={{ mt: 4 }}>
            <Toolbar>

              <Search
                style={{ backgroundColor: '#008080' }}>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  onChange={(e) => this.searchInput(e.target.value)}
                  placeholder={t('main.btn_search')}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </Search>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { md: 'flex' } }}>  

                <StyledToggleButtonGroup
                  size="small"
                  // exclusive
                  style={{ backgroundColor: '#008080' }}
                  value={this.state.selectedHashtags}
                  onChange={(event, newValue) => { this.selectHashtags(newValue) }}
                >
                  {
                    this.state.hashtags?.map((item, i) => (
                      <ToggleButton
                        component="label"
                        variant="contained"
                        // classname="MuiButtonBase-root" style={{ backgroundColor: 'blue' }} 
                        value={item.name[lng]}
                        aria-label="bold">
                        <Typography
                        >
                          {item.name[lng]}
                        </Typography>
                      </ToggleButton>
                    ))}
                </StyledToggleButtonGroup>
                 </Box>

               <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { md: 'flex' } }}>  
            
              <FormControl  
                                variant="standard" 
                              sx={{  m: 1, minWidth: 110, 
                              backgroundColor: '#008080', 
                            
                              }}>
                  <InputLabel id="demo-simple-select-standard-label">{t('main.lbl_domain')}</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={this.state.selectedDomain}
                    onChange={this.selectInstitutions}
                   
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {
                      this.state.domains.map((item, i) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  
                    ) ) }
                  </Select>
                </FormControl>

               </Box>  


              
            </Toolbar>
          
           
           
            
          </AppBar>
        

            



        </Box>
        


        

        <Suspense fallback={(<div>Loading</div>)}>
          {this.state.itemsView.length === 0 ?
            <>
              <Typography>
                <span
                  style={{
                    width: "30vw",
                    left: "32vw",
                    top: "166px",
                    fontSize: "22px",
                    color: "#004c43",
                    zIndex: 100,
                    position: "absolute",
                    backgroundColor: "#ffffff7a",
                  }}
                >
                  {t('main.alert_itemsnotfound')}
                </span>
              </Typography>
            </>
            :
            <>

              <Box sx={{
                bottom: "5%",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                position: 'absolute',
                width: "100%",
                // m:2,

                // paddingBottom:"33px",
              }}>

              </Box>
              <div style={{ position: 'absolute', bottom: 20, right: 8 }}>
                <Accordion style={{ backgroundColor: "#008080" }}>
                  <AccordionSummary
                    expandIcon={<ExpandLessIcon style={{ color: "#FFF" }} />}
                    id="panel1a-header"
                  >
                    <ScatterPlotIcon style={{ color: "#FFF", marginRight: "20px" }} />
                    <Typography
                      variant="button"
                      display="block"
                      color="#FFF"
                      style={{ fontWeight: "bold" }}
                    >
                      Timeline view
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TimelineChart items={this.state.itemsView} />
                  </AccordionDetails>
                </Accordion>
              </div>
            </>
          }
        </Suspense>
      </>
    )
  }

}
export default withTranslation()(MapList);